import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import titleMixin from './mixins/titleMixin'
import { stateSymbol, createState } from './store';

const app = createApp(App)

app.directive('click-outside', {
    beforeMount(el, binding) {
        el.clickOutsideEvent = function(event) {
        const validLocation = event.pageX < 51 && event.pageY < 51;
        if (!(el === event.target || el.contains(event.target) || validLocation)) {
            binding.value(event, el);
        }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

app.use(router)
app.mixin(titleMixin)
app.provide(stateSymbol, createState());
app.mount('#app')

// createApp(App).use(router).mixin(titleMixin).mount('#app')
