<template>
    <div class="top-hero" :class="{mini: !isHome}">
        <div class="names">
            Steph & Ansel
        </div>
        <DaysUntil />
    </div>
</template>

<script>
import DaysUntil from './DaysUntil'
import { useState } from '@/store.js';

export default {
    components: {
        DaysUntil
    },
    setup() {
      return { state: useState() };
    },
    computed: {
        isHome() {
            return this.state.isHome
        }
        
    }
    
}
</script>

<style scoped>
    .top-hero {
        font-size: 5rem;
        font-family: 'Pinyon Script', cursive;
        margin-top: 10px;
        color: black;
    }

    .mini {
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 4em;
    }
    
</style>