<template>
  <DesktopHome v-if="!isMobile" />
  <MobileHome v-else />
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import DesktopHome from '@/components/Home/DesktopHome'
import MobileHome from '@/components/Home/MobileHome'

export default {
  title: 'Home',
  name: 'Home',
  components: {
    DesktopHome,
    MobileHome
  },
  computed: {
    isMobile() {
      return isMobile
    }
  },
}
</script>
