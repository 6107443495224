<template>
    <div class="mobile-home flex column">
        <div class="mobile-header flex column">
            Steph & Ansel
            <DaysUntil />
        </div>
        <div class="mobile-hero-container flex">
            <div class="mobile-hero">

            <img class="photo-credit" src="../../assets/watermark.png" />
            </div>
        </div>
        <div class="mobile-detail flex column">
  
        </div>
    </div> 
</template>

<script>
import DaysUntil from './DaysUntil'

export default {
    components: {
        DaysUntil,
    }
}
</script>

<style scoped>
.mobile-home {
    background-color: #990147;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: 'Pinyon Script', cursive;
    color: white;
  }

  .mobile-header {
    font-size: 3.75em;
    flex-grow: 1;
  }

  .mobile-hero-container {
    height: 25%;
  }

  .mobile-hero {
    background-image: url("../../assets/Wedding_Photos/Ansel & Steph Wedding-19.jpg");
    background-position: center;
    background-size: 300px;
    background-position: 240px -10px;
    border-radius: 50%;
    width: 175px;
    height: 175px;
    position: relative;
  }

  .photo-credit {
        position: absolute;
        bottom: 0;
        right: calc(50% - 37.5px);
        max-width: 75px;
        width: auto;
        height: auto;
    }


  .mobile-detail {
    font-size: 1.5rem;
    flex-grow: 1;
  }

  span {
    font-family: Arial, Helvetica, sans-serif;
    font-size: .7em;
    font-style: italic;
  }

  p {
    padding: 0;
    margin: 0;
  }
</style>