<template>
    <router-link class="menu-button" :to="route">{{title}}</router-link>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        pageName: String,
        title: String,
        top: String,
        bottom: String,
        left: String,
        right: String
    },
    computed: {
        route() {
            return this.pageName || ''
        },
    }
}
</script>

<style scoped>
    .menu-button {
        width: auto;
        display: flex;
    }

    a {
        text-decoration: none;
        font-size: 1.5em;
        font-weight: bolder;
        color: black;
        font-family: Garamond, serif;
        align-items: center;
        justify-content: center;
    }
</style>