<template>
    <div class="top-nav">
        <MenuItem title="Home" pageName="/" />
        <MenuItem title="Info" pageName="info" />
        <MenuItem v-if="false" title="RSVP" pageName="rsvp" />
        <MenuItem title="Registry" pageName="registry" />
        <MenuItem title="Photos" pageName="photos" />
        <MenuItem title="Ceremony" pageName="ceremony" />
    </div>
</template>

<script>
import MenuItem from './MenuItem.vue'

export default {
    components: {
        MenuItem
    }
}
</script>

<style scoped>
    .top-nav {
        width: 60%;
        margin: auto;
        margin-bottom: 20px; margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>