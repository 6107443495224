<template>
    <div class="date">
        {{daysUntil}} days until 04/02/2022
    </div>
</template>

<script>
export default {
    data() {
        return {
            daysUntil: this.calculateDays()
        }
    },
    methods: {
        calculateDays() {
            const today = new Date();
            const wedding = new Date(2022,3,2);
            const diff = wedding.getTime() - today.getTime();
            return  Math.ceil(diff / (1000 * 3600 * 24));
        }
    }
}
</script>

<style scoped>
    .date {
        font-size: 1.6rem;
        font-family: 'Pinyon Script', cursive;
    }
</style>