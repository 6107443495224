<template>
    <div class="top-container">
        <TopHero />
        <NavLinks />
    </div>
    <router-view/>
</template>

<script>
import NavLinks from './Navigation/NavLinks.vue'
import TopHero from './TopHero.vue'

export default {
    components: {
        NavLinks,
        TopHero
    }
}
</script>

<style scoped>
    .top-container {
        box-shadow: 0px 1px 4px black;
        z-index: 2;
        background-color: rgba(242, 175, 109, .37);
    }
</style>