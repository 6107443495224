<template>
  <DesktopController v-if='!isMobile'/>
  <MobileController v-if='isMobile' />
</template>

<script>
  import DesktopController from '@/components/Home/DesktopController.vue'
  import MobileController from '@/components/Home/MobileController.vue'
  import { isMobile } from 'mobile-device-detect'
  export default {
    components: {
      DesktopController,
      MobileController
    },
    computed: {
      isMobile() {
        return isMobile
      }
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; 
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: rgba(255,255,255, .8);
  overflow: hidden;
}

.flower {
  background-image: url('assets/floral_background.png');
  background-size: 756px 616px;
  background-position: right;
  background-repeat: no-repeat;
 
  height: 100%;
  padding:0;
  margin:0;
}

h1 {
  font-family: 'Pinyon Script', cursive;
  font-size: 3em;
  padding: 0; margin-top: 20px; margin-bottom: 10px;
}

.page-content {
  margin:0;
  padding:15px;
  max-width: 100%;
  min-height: 94%;
}

.scrollable {
  overflow: auto;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column {
  flex-direction: column;
}

</style>
