<template>
    <div class="home flex">
        <!-- <div class="hero flex">
         </div> -->
    </div>
    
        <!-- Joshua Benda Photography -->
        <img class="photo-credit" src="../../assets/watermark.png" />

        
</template>

<script>
import { useState } from '@/store.js';

export default {
    setup() {
        return { state: useState() };
    },
    created: function(){
        this.state.isHome = true;
    },
}
</script>

<style scoped>
    .hero {
        font-weight: bold;
        font-size: 2em;
        margin-left: 50px; margin-right: 50px;
        background-color: rgba(255,255,255,.6);
        padding: 25px;
        border-radius: 8px;
        flex-direction: column;
        font-family: Arial, Helvetica, sans-serif;
    }

    .home {
        background-image: url("../../assets/Wedding_Photos/Ansel & Steph Wedding-19.jpg");
        background-position: center -130px;
        background-size: 1450px;
        flex-grow: 2;
    }

    .photo-credit {
        position: absolute;
        bottom: 0;
        right: 0;
        padding:0;
        margin: 15px;
        max-width: 250px;
        width: auto;
        height: auto;
    }

    span {
        font-size: .9em;
    }
</style>