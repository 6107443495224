<template>
    <div class="nav" :style="style" v-click-outside="closeNav">
        <div id="nav-toggle" class="nav-toggle" :onclick="toggleNav" :style="toggleBack">
            <svg v-if='state.isMinimized' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
        </div>
        <div v-if='!state.isMinimized' class="nav-body">
            <div class='menu-header mobile-link'> Menu </div>
            <div class='divider'></div>
            <router-link class="mobile-link" v-on:click="closeNav" to="/">Home</router-link>
            <div class='divider'></div>
            <router-link class="mobile-link" v-on:click="closeNav" to="info">Info</router-link>
            <div class='divider'></div>
            <router-link class="mobile-link" v-if="false" v-on:click="closeNav" to="rsvp">RSVP</router-link>
            <div class='divider' v-if="false" ></div>
            <router-link class="mobile-link" v-on:click="closeNav" to="registry">Registry</router-link>
            <div class='divider'></div>
            <router-link class="mobile-link" v-on:click="closeNav" to="photos">Photos</router-link>
            <div class='divider'></div>
            <router-link class="mobile-link" v-on:click="closeNav" to="ceremony">Ceremony</router-link>
            <div class='divider'></div>
            <div class='logo'>L</div>
        </div>
    </div>

    
</template>

<script>
import { useState } from '@/store.js';

export default {
    setup() {
      return { state: useState() };
    },
    data() {
        return {
            background: 'rgba(255,255,255,.7)',
            shadow: '1px 1px 5px darkgray',
            width: '50px',
            height: '50px'
        }
    },
    computed: {
        style() {
            return {
                width: this.width,
                height: this.height
            }
        },
        toggleBack() {
            return {
                backgroundColor: this.background,
                boxShadow: this.shadow
            }
        }
    },
    methods: {
        toggleNav() {
            this.state.isMinimized = !this.state.isMinimized;
            this.width = this.state.isMinimized ? '50px' : '60%';
            this.background = this.state.isMinimized ? 'rgba(255,255,255,.7)' : 'rgba(255,255,255,0)';
            this.shadow = this.state.isMinimized ? '1px 1px 5px darkgray' : '';
            this.height = this.state.isMinimized ? '50px' : 'auto';
        },
        closeNav() {
           this.state.isMinimized = true;
           this.width = '0%';
           this.background = 'rgba(255,255,255,.7)';
           this.shadow = '1px 1px 5px darkgray';
        }
    },
}
</script>

<style scoped>
    .nav {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 4;
        height: 50px;
    }
    .nav-toggle {
        position: relative;
        height: 50px;
        width: 50px;
        z-index: 5;
    }

    .nav-body {
        position: relative;
        width: 100%;
        height: 100%;
        margin-top: -50px;
        background-color: rgba(249,249,249);
        display: flex;
        flex-direction: column;
        box-shadow: 1px 1px 5px darkgray;
    }

    .mobile-link {
        text-decoration: none;
        padding: 15px;
    }
    
    .divider {
        border-top: 1.5px solid rgba(128, 128, 128, .6);
    }

    .menu-header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .logo {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Times New Roman', Times, serif;
        width: 100%;
        height: 100%;
        font-size: 100px;
    }

    svg {
        width: 100%;
        height: 100%;
    }

</style>