<template>
    <MobileNav />
    <router-view/>
</template>

<script>
import MobileNav from './Navigation/MobileNav.vue'

export default {
    components: {
        MobileNav
    }
}
</script>